import React from "react";

function Feature() {
  return (
    <div className="w-screen my-12 lg:my-28 flex flex-col items-center relative">
      <div className="relative z-0">
        <img
          className="h-[150px] md:h-auto w-screen"
          src="Feature.png"
          alt="Feature"
        />
      </div>
      <div className="absolute inset-0 flex flex-col justify-center items-center lg:space-y-4 LapTopL:space-y-8 z-10 py-4">
        <h1 className="text-center text-2xl md:text-4xl lg:text-5xl LapTopL:text-7xl 3xl:text-[90px] 4xl:text-[120px] font-semibold">
          Find a Provider Now
        </h1>
        <div className="flex flex-col md:flex-row justify-center items-center gap-2 lg:gap-3 4xl:gap-5 mx-auto  mt-4 md:mt-6 LapTopL:mt-8">
          <button
            style={{ backgroundColor: "#F8842C" }}
            className="whitespace-nowrap text-[8px] lg:text-sm LapTopL:text-lg 3xl:text-xl 4xl:text-3xl font-semibold text-white rounded-lg py-2 md:py-4 px-4 w-[180px] md:w-[120px] lg:w-[170px] lg:h-[53px] LapTopL:w-[220px] LapTopL:h-[66px] 3xl:w-[280px] 3xl:h-[90px] 4xl:w-[380px] 4xl:h-[120px]"
          >
            Provider Directory
          </button>
          <button
            style={{ backgroundColor: "#005B8C" }}
            className=" whitespace-nowrap text-[8px] lg:text-sm LapTopL:text-lg 3xl:text-xl 4xl:text-3xl font-semibold text-white rounded-lg py-2 md:py-4 px-4 w-[180px] md:w-[100px] lg:w-[134px] lg:h-[53px] LapTopL:w-[190px] LapTopL:h-[66px] 3xl:w-[220px] 3xl:h-[90px] 4xl:w-[300px] 4xl:h-[120px]"
          >
            Get Matched
          </button>
        </div>
      </div>
    </div>
  );
}

export default Feature;
