import React from "react";
import { useLocation } from "react-router-dom";
import useGetSpecificProviders from "./hooks/useGetSpecificProviders";
import Loader from "./Loader";

function EtheraProvider() {
    const location = useLocation();
    console.log({ locationPathname: location.pathname });
    const { data, isLoading } = useGetSpecificProviders({
        id: location.pathname.split("/")[location.pathname.split("/").length - 1],
    });
    console.log({ data });
    if (!isLoading) {
        return (
            <>
                <div
                    style={{ backgroundColor: "#42C6A5" }}
                    className="relative h-[400px] lg:h-[661px] 3xl:h-[720px] 4xl:h-[860px]"
                >
                    <img
                        src="/grain background.png"
                        className="h-full w-full absolute inset-0 z-0"
                        alt="Main background"
                    />
                    <svg className="hidden lg:block absolute lg:translate-y-72 lg:translate-x-32 3xl:translate-x-52 4xl:translate-x-72" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="13.5" cy="13.5" r="13.5" transform="matrix(-1 0 0 1 27 0)" fill="#F8842C" />
                    </svg>
                    <svg className="hidden lg:block lg:translate-y-[500px] lg:translate-x-44 3xl:translate-x-64 4xl:translate-x-80" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="white" />
                    </svg>
                    <svg className="hidden lg:block absolute right-0 lg:translate-y-[350px] lg:-translate-x-44 LapTopL:-translate-x-72 3xl:-translate-x-80" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(-1 0 0 1 15 0)" fill="#FFC107" />
                    </svg>
                    <svg className="hidden lg:block absolute right-0 lg:translate-y-[510px] LapTopL:translate-y-[490px] lg:-translate-x-36 LapTopL:-translate-x-44 3xl:-translate-x-56" width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="13.5" cy="13.5" r="13.5" transform="matrix(-1 0 0 1 27 0)" fill="#F8842C" />
                    </svg>

                    <div className="absolute lg:-translate-y-16  inset-0 m-auto flex flex-col items-center justify-center">
                        <img
                            src={data?.profilePicture}
                            className="z-10 w-[120px] h-[120px] lg:w-[220px] lg:h-[220px] 3xl:w-[260px] 3xl:h-[260px] 4xl:w-[320px] 4xl:h-[320px] rounded-full object-cover"
                            alt="Foreground overlay"
                        />

                        <h1 className="z-10 text-white mt-8 text-2xl lg:text-5xl  3xl:text-6xl 4xl:text-7xl font-semibold">
                            {data?.name}
                        </h1>
                        <h1 className="z-10 text-white opacity-80 text-lg lg:text-lg 3xl:text-2xl 4xl:text-3xl mt-2">
                            {data?.providerType}
                        </h1>
                        <h1 className="z-10 text-white text-lg lg:text-lg 3xl:text-2xl 4xl:text-3xl mt-2">
                            {data?.address}
                        </h1>
                    </div>
                </div>

                <div className="lg:flex justify-between w-[80%] mx-auto lg:gap-6 LapTopL:gap-7  2xl:gap-8 3xl:gap-10 rounded-xl relative lg:-translate-y-28 LapTopL:-translate-y-32">
                    <div className="basis-[100%] overflow-y-scroll lg:basis-[60%] w-full md:h-[750px] lg:h-[860px] mt-10 lg:mt-0 bg-white flex flex-col gap-2 md:gap-2  LapTopL:gap-6 md:pt-6 md:pb-6 LapTopL:pt-12 px-4 md:px-8 shadow-xl rounded-xl">
                        <div>
                            <h1 className="text-sm md:text-xl 2xl:text-3xl font-semibold">
                                Service Type:
                            </h1>
                            <p
                                style={{ color: "#757575" }}
                                className="mt-1 LapTopL:mt-2 text-[10px] md:text-base 2xl:text-lg tracking-tighter LapTopL:tracking-normal"
                            >
                                {data?.Service}
                            </p>
                        </div>
                        <div>
                            <h1 className="text-sm md:text-xl 2xl:text-3xl  font-semibold">
                                Focus:
                            </h1>
                            <p
                                style={{ color: "#757575" }}
                                className="mt-1 LapTopL:mt-2 text-[10px] md:text-base 2xl:text-lg tracking-tighter LapTopL:tracking-normal"
                            >
                                {data?.Focus}
                            </p>
                        </div>
                        <div>
                            <h1 className="text-sm md:text-xl 2xl:text-3xl  font-semibold">
                                Therapy Styles:
                            </h1>
                            <p
                                style={{ color: "#757575" }}
                                className="mt-1 LapTopL:mt-2 text-[10px] md:text-base 2xl:text-lg tracking-tighter LapTopL:tracking-normal"
                            >
                                {data?.TherapyStyle}
                            </p>
                        </div>
                        <div>
                            <h1 className="text-sm md:text-xl 2xl:text-3xl  font-semibold">
                                Meet {data?.name}:
                            </h1>
                            <div style={{ color: "#757575" }} dangerouslySetInnerHTML={{ __html: data?.bioData }}></div>
                            {/* <p
                style={{ color: "#757575" }}
                className="mt-1 LapTopL:mt-2 text-[10px] md:text-base tracking-tighter LapTopL:tracking-wide"
                >
                {data?.bioData}
              </p> */}
                        </div>
                    </div>
                    <div className=" basis-[100%] ml-0 lg:basis-[40%] md:h-[800px] lg:h-[860px] mt-12 lg:mt-0 right-0 bg-white gap-6 p-6 md:pt-6 lg:pt-10  shadow-xl rounded-xl">
                        <div className="flex mt-4">
                            <ul className="flex flex-col gap-y-4 md:gap-y-6 -translate-y-2">
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            License:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base ">{data?.license}</p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Schedule:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">
                                            Afternoons, Evenings, Monday, Tuesday
                                        </p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Availability:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">
                                            {data?.Availibility}{" "}
                                        </p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Session Type:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">
                                            {data?.sessionType}
                                        </p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Languages:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">
                                            {data?.Languages}
                                        </p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Location:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">{data?.Location}</p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Billing Options:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">
                                            {data?.BillingOptions}
                                        </p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Insurance:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">
                                            {data?.Insurance}
                                        </p>
                                    </div>
                                </li>
                                <li className="flex items-baseline gap-x-2">
                                    <div className="bg-[#F8842C] w-3.5 h-3.5 rounded-full" />
                                    <div>
                                        <p className="text-sm md:text-lg lg:text-xl font-semibold">
                                            Rates:
                                        </p>
                                        <p style={{ color: "#757575" }} className="text-[10px] md:text-base">{data?.rates}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className=" my-12 lg:my-28 flex flex-col items-center relative">
                    <div className="relative z-0">
                        <img
                            className="h-[180px] md:h-auto  w-screen"
                            src="/Feature.png"
                            alt="Feature"
                        />
                    </div>
                    <div className=" absolute translate-y-8 md:translate-y-14 lg:translate-y-20 xl:translate-y-24 LapTopL:translate-y-28 2xl:translate-y-32 3xl:top-4 4xl:top-24 z-10">
                        <h1 className="text-center text-xl md:text-3xl lg:text-5xl LapTopL:text-6xl 2xl:text-6xl 3xl:text-7xl 4xl:text-8xl font-semibold">
                            Ready to connect With
                        </h1>
                        <h1 className="text-center text-xl md:text-3xl lg:text-5xl LapTopL:text-6xl 2xl:text-6xl 3xl:text-7xl 4xl:text-8xl font-semibold">
                            Stephanie Pappas
                        </h1>
                        <div className="flex flex-col md:flex-row justify-center items-center gap-2 3xl:gap-3 mx-auto mt-4 md:mt-6 LapTopL:mt-8">
                            <button
                                style={{ backgroundColor: "#F8842C" }}
                                className="whitespace-nowrap text-[8px] lg:text-sm LapTopL:text-lg 3xl:text-xl 4xl:text-2xl font-semibold text-white rounded-lg py-2 md:py-4 px-4 w-[180px] md:w-[120px] lg:w-[180px] lg:h-[53px] LapTopL:w-[200px] LapTopL:h-[66px] 3xl:w-[260px] 3xl:h-[82px] 4xl:w-[300px] 4xl:h-[100px] "
                            >
                                Provider Directory
                            </button>
                            <button
                                style={{ backgroundColor: "#005B8C" }}
                                className=" whitespace-nowrap text-[8px] lg:text-sm LapTopL:text-lg 3xl:text-xl 4xl:text-2xl font-semibold text-white rounded-lg py-2 md:py-4 px-4 w-[180px] md:w-[100px] lg:w-[154px] lg:h-[53px] LapTopL:w-[180px] LapTopL:h-[66px] 3xl:w-[230px] 3xl:h-[82px] 4xl:w-[270px] 4xl:h-[100px]"
                            >
                                Get Matched
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="flex flex-col items-center justify-center h-screen -translate-y-20 gap-2 lg:gap-0">
                    <svg
                        className="h-[40px] lg:h-[130px]"
                        width="92"
                        height="80"
                        viewBox="0 0 46 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.1579 17.7738C7.82878 17.9242 4.55374 18.6809 1.48983 20.0074C1.02747 20.207 -0.0154108 20.7094 1.1261e-06 20.723C0.00553363 20.7282 0.244617 20.6722 0.533493 20.6006C1.86564 20.2626 3.79372 19.917 5.25983 19.7522C7.56649 19.4914 10.5118 19.4702 13.0626 19.699C14.1478 19.7946 14.8097 19.893 17.2515 20.3238C25.6139 21.7946 30.4612 22.3478 34.1107 22.2438C37.2326 22.1562 40.6943 21.3558 43.8787 19.9834C44.3924 19.763 45.476 19.2442 45.4594 19.2282C45.4566 19.2258 45.2361 19.2734 44.9682 19.3398C41.5266 20.1722 39.9889 20.3586 36.1273 20.4062C34.6169 20.4222 34.1387 20.4062 33.014 20.2946C31.5641 20.1486 30.0036 19.9302 26.0652 19.3242C21.738 18.6566 19.6704 18.3694 17.5266 18.1274C14.8943 17.835 12.6264 17.707 11.1555 17.7738"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.7953 20.1138C6.72318 20.159 5.88777 20.223 5.04446 20.3238C4.36673 20.4038 2.98479 20.619 2.96108 20.6458C2.90575 20.707 3.02945 22.1322 3.17369 23.1454L3.2053 23.3662H6.02688C8.70421 23.3662 8.84885 23.3634 8.83541 23.3182C8.82751 23.2942 8.78562 23.1002 8.74373 22.8874C8.59079 22.0958 8.49831 21.2934 8.46711 20.4874L8.44853 20.0966L8.20708 20.1018C8.07549 20.1046 7.89094 20.1098 7.79728 20.115"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.39854 24.3422C3.63099 25.3778 3.93876 26.3946 4.31931 27.3843L4.49556 27.8339H10.7394L10.6264 27.6531C10.1126 26.8391 9.53844 25.6187 9.21004 24.6318L9.09742 24.297H6.23988C3.53646 24.297 3.38669 24.2994 3.39736 24.3422"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M36.2008 24.6318C35.8751 25.6055 35.3389 26.7623 34.8267 27.5867L34.6742 27.8339H40.9141L41.0899 27.3843C41.4708 26.3946 41.7791 25.3778 42.0123 24.3422C42.0225 24.2994 41.8728 24.297 39.1693 24.297H36.3138L36.2008 24.6318Z"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.82632 28.5651C4.82632 28.5943 5.20491 29.3443 5.3938 29.6851C5.78974 30.4088 6.2284 31.1076 6.70738 31.7779L6.9674 32.1423H15.0018L14.7402 31.9663C13.4586 31.0846 12.3246 30.001 11.3812 28.7567L11.2262 28.5519H8.0253C6.26478 28.5519 4.82435 28.5571 4.82435 28.5651"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M34.0411 28.7435C33.0936 29.9929 31.9548 31.0809 30.6679 31.9663L30.4078 32.1419H38.4418L38.7018 31.7775C39.1808 31.1072 39.6195 30.4084 40.0154 29.6847C40.2047 29.3443 40.5829 28.5943 40.5829 28.5647C40.5829 28.5567 39.1433 28.5515 37.3819 28.5515H34.1857L34.0411 28.7435Z"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.40644 32.6923C7.85813 33.2375 8.23118 33.6495 8.72515 34.1495C9.48982 34.9236 10.0415 35.4128 10.835 36.0192L11.0504 36.184H34.3592L34.5746 36.0192C35.7693 35.102 36.8617 34.0558 37.8325 32.8991L38.03 32.6591L33.8107 32.6511L29.5914 32.6459L29.063 32.9091C27.4033 33.7415 25.8502 34.1855 23.9376 34.3799C23.4855 34.4251 21.9198 34.4251 21.4681 34.3799C19.5317 34.1831 17.9056 33.7123 16.2517 32.8667L15.821 32.6459H11.5926C7.57044 32.6459 7.36811 32.6487 7.40368 32.6911"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.8016 36.708C13.998 38.1944 16.6461 39.2608 19.276 39.7208C20.5161 39.9364 21.299 40.0008 22.7046 40.0008C24.1103 40.0008 24.8931 39.9372 26.1332 39.7208C28.7631 39.2608 31.4112 38.1944 33.6076 36.708L33.8704 36.5296H11.5392L11.8016 36.708Z"
                            fill="#016D9D"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.789 0.0196109C17.9828 0.191993 14.3072 1.47522 11.2054 3.71454C8.10355 5.95387 5.70795 9.05366 4.30745 12.6401C3.55661 14.5626 3.14365 16.4162 2.9674 18.6738L2.95159 18.8654L3.53764 18.6714C4.83769 18.2435 6.16414 17.9026 7.5084 17.651C7.74748 17.6058 8.64612 17.4622 8.68524 17.4622C8.69315 17.4622 8.70658 17.4062 8.71686 17.337C8.82711 16.5738 9.26339 15.151 9.66805 14.217C10.5979 12.0852 12.0233 10.2127 13.8228 8.75926C15.6223 7.30582 17.7425 6.31457 20.0024 5.87007C20.9192 5.68927 21.6811 5.62007 22.6955 5.62007C23.9747 5.62007 25.0046 5.74767 26.2158 6.06007C31.0611 7.30088 34.9733 11.1329 36.3517 15.9862C36.6649 17.0608 36.8492 18.1698 36.9006 19.289L36.9322 19.8794L36.9978 19.8686C37.0346 19.8634 37.3535 19.8502 37.7092 19.8422C39.4432 19.8022 40.7149 19.6774 42.3778 19.3846C42.4592 19.3714 42.4643 19.3634 42.4513 19.273C42.4462 19.2198 42.4331 19.015 42.4252 18.8182C42.3442 16.8802 41.8494 14.6002 41.0832 12.6401C38.9816 7.26088 34.5758 2.98204 29.2108 1.10722C26.8269 0.273692 24.3086 -0.0953467 21.789 0.0196109Z"
                            fill="#F89A40"
                        />
                    </svg>
                    <Loader />
                </div>
            </>
        );
    }
}

export default EtheraProvider;
