import { useMutation } from "react-query";
import { BASE_URL } from "../utils/constants";
import { post } from "../utils/axios";
import Cookies from "js-cookie";

const useLogin = () => {
  const {
    mutate: login,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: (values) => post(`${BASE_URL}auth?target=login`, values),
    onSuccess: (response) => {
        console.log(response.access);
        
        Cookies.set("accessToken", response.access);
      //   successToast(response.message);
      //   callback(response.data.accessToken, response.data.refreshToken);
    },
    onError: (error) => {
      //   errorToast(error.response?.data.message);
    },
  });
  return { login, loading, error };
};
export default useLogin;
