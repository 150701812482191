import React from "react";

const TextInput = ({
  placeholder,
  value,
  onChange,
  labelFor,
  labelText,
  required,
}) => {
  return (
    <div className="basis-1/2 space-y-1">
      <label
        for={labelFor}
        class="block mb-2 text-sm font-medium text-gray-900"
      >
        {labelText}
      </label>
      <input
        type="text"
        id={labelFor}
        className="min-w-full bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-[#f8842c99] focus:border-[#f8842c99] focus-visible:border-[#f8842c99] border-2 block w-full p-2.5"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

export default TextInput;
