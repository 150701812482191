import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
function Card({ img, name, miniHeading, location, description, id }) {
    return (
        <Link
            to={`/Provider/${id}`}
            className="w-[100%]  mobile:h-[140px] md:h-[208px] lg:h-auto px-3.5  p-2 2xl:p-4 md:p-5 border rounded-lg bg-white transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
        >
            <div className="flex gap-3 LapTopL:gap-5 3xl:gap-8 md:gap-4 w-[100%] h-auto 4xl:p-6 ">
                <div className="">
                    <img
                        alt="profile"
                        src={img}
                        className="w-[140px] h-[110px] mobile:mt-1 md:w-[180px] md:h-[150px] lg:w-[240px] lg:h-[160px] LapTopL:w-[280px] LapTopL:h-[180px] 2xl:h-[200px] 2xl:w-[300px] 3xl:w-[330px] 3xl:h-[240px] 4xl:w-[400px] 4xl:h-[290px] rounded-lg"
                        // style={{ backgroundImage: `url(${img})`,aspectRatio: '140 / 110', backgroundSize: 'cover', backgroundPosition: 'center' }}
                    />
                </div>
                <div className="flex flex-col gap-1 LapTopL:gap-3 2xl:gap-3.5 md:space-y-1 lg:space-y-0.5 w-full">
                    <h1 className="font-semibold text-sm md:text-xl lg:text-xl LapTopL:text-2xl 3xl:text-3xl 4xl:text-5xl">
                        {name}
                    </h1>
                    <h2 className="text-[#BABABA] mobile:text-[12px] md:text-sm LapTopL:text-xl 3xl:text-2xl 4xl:text-3xl">
                        {miniHeading}
                    </h2>
                    <div className="flex  items-center gap-2 w-full">
                        <svg
                            className="w-3 md:w-4 LapTopL:w-5 3xl:w-6 4xl:w-8"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 12 15"
                        >
                            <path
                                fill="#F8842C"
                                fillOpacity="0.88"
                                d="m6 14.819-4.243-4.243a6 6 0 1 1 8.486 0zm3.3-5.186a4.666 4.666 0 1 0-6.6 0l3.3 3.3zM6 7.667A1.333 1.333 0 1 1 6 5a1.333 1.333 0 0 1 0 2.667"
                            ></path>
                        </svg>
                        <h2 className="font-normal mobile:text-[10px] md:text-sm 3xl:text-xl 4xl:text-3xl">
                            {location}
                        </h2>
                    </div>
                    <hr style={{ backgroundColor: "#D6D6D6", height: "2px", width: "100%", margin: "8px 0px" }} />
                    <h1 className="font-normal text-[9px] mobileL:text-[10px] md:text-sm lg:text-sm LapTopL:text-base 3xl:text-2xl 4xl:text-4xl ">
                        {description}
                    </h1>
                </div>
            </div>
        </Link>
    );
}

export default Card;