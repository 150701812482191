import React, { useRef, useState } from "react";
import { useClickOutside } from "../hooks/useOutsideClick";

const Dropdown = ({ title, items, onClick, label, multiple, selectedIds }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownClick = (item) => {
    console.log({ item });

    onClick(item);
    if (!multiple) setIsOpen(false);
  };
  useClickOutside(ref, () => setIsOpen(false));
  return (
    <div className="w-full py-2">
      <p className="ml-1 mb-2">{label}</p>
      <div className="relative inline-block w-full" ref={ref}>
        <button
          type="button"
          className="whitespace-nowrap min-w-full max-w-44 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-[#f8842c99] focus:border-[#f8842c99] focus-visible:border-[#f8842c99] border-2 w-full p-2.5 font-medium inline-flex items-center justify-between"
          onClick={toggleDropdown}
        >
          <span className="max-w-[80%] overflow-scroll">{title}</span>
          <svg
            class="w-2.5 h-2.5 ml-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-2 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 w-full">
            <ul
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {items.map((item, index) => {
                return (
                  <li key={index}>
                    <p
                      className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer ${
                        selectedIds.includes(item.id) ? "bg-[#f8842c99]" : ""
                      }`}
                      onClick={() => handleDropdownClick(item)}
                    >
                      {item.name}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
