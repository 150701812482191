import React, { useEffect, useState } from "react";
import Card from "./card";
import useGetProviders from "./hooks/getProviders";
import Loader from "./Loader";
function MainSection() {
    const details = [
        {
            img: "image 39.png",
            name: "Meghan Malloy",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "image 40.png",
            name: "Stephanie Pappas",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "AndrewLampley-scaled 1.png",
            name: "Dr. Andrew Lampley",
            miniHeading: "MD (Doctor of Medicine)",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "ethera-irvine-elena-welsh-psychologist-scaled 1.png",
            name: "Dr. Elena Welsh",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "image 39.png",
            name: "Amanda Cline",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "image 39.png",
            name: "Sierra Leblanc",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "ethera-irvine-elena-welsh-psychologist-scaled 1.png",
            name: "Dr. Elena Welsh",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "image 39.png",
            name: "Dr. Andrew Lampley",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "image 39.png",
            name: "Dr. Robert Yeilding",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
        {
            img: "ethera-irvine-elena-welsh-psychologist-scaled 1.png",
            name: "Kate Kowalczik",
            miniHeading: "AMFT-MDS",
            location: "North Orange Country",
            description: "Individual Therapy,couples Therapy,family Therapy",
        },
    ];
    const { data: providers, isLoading: providersLoading } = useGetProviders();
    console.log({ providers });

    return (
        <div className=" w-screen gap-y-4 mt-4 lg:mt-0 3xl:mt-14 4xl:mt-28">
            <div className="grid grid-cols-1 lg:grid-cols-2 w-[90%] md:w-[80%] gap-y-4 lg:gap-y-6 4xl:gap-y-10 4xl:gap-x-10 gap-x-6 mobile:mx-4 md:mx-auto">
                {providersLoading ? (
                    <>
                        <div className="flex flex-col mt-8 lg:mt-0 lg:translate-x-52 LapTopL:translate-x-[296px] 2xl:translate-x-[312px] 3xl:translate-x-[390px] 4xl:translate-x-[520px] items-center w-full gap-2 lg:gap-0">
                            <Loader />
                        </div>
                    </>
                ) : (
                    providers.map(
                        ({ id, profilePicture, name, providerType, Location, Focus }, index) => (
                            <Card
                                id={id}
                                key={index}
                                img={profilePicture}
                                name={name}
                                miniHeading={providerType}
                                location={Location}
                                description={Focus}
                            />
                        )
                    )
                )}
            </div>
            <div className="flex justify-center items-center mt-8">
                <button>
                    <svg
                        className="w-36 md:w-52 lg:w-64 LapTopL:w-72 3xl:w-96 4xl:w-[500px] 4xl:mt-6"
                        viewBox="0 0 308 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 18C0 17.1716 0.671573 16.5 1.5 16.5H118.5C119.328 16.5 120 17.1716 120 18C120 18.8284 119.328 19.5 118.5 19.5H1.5C0.671571 19.5 0 18.8284 0 18Z"
                            fill="#D6D6D6"
                        />
                        <rect
                            x="136.75"
                            y="0.75"
                            width="34.5"
                            height="34.5"
                            rx="3.25"
                            fill="#FFF9E6"
                        />
                        <rect
                            x="136.75"
                            y="0.75"
                            width="34.5"
                            height="34.5"
                            rx="3.25"
                            stroke="#F8842C"
                            stroke-width="1.5"
                        />
                        <g clip-path="url(#clip0_1_308)">
                            <path
                                d="M159.75 16.75H155.5C155.434 16.75 155.37 16.7237 155.323 16.6768C155.276 16.6299 155.25 16.5663 155.25 16.5V12.25C155.25 11.5597 154.69 11 154 11C153.31 11 152.75 11.5597 152.75 12.25V16.5C152.75 16.5663 152.724 16.6299 152.677 16.6768C152.63 16.7237 152.566 16.75 152.5 16.75H148.25C147.56 16.75 147 17.3097 147 18C147 18.6903 147.56 19.25 148.25 19.25H152.5C152.566 19.25 152.63 19.2763 152.677 19.3232C152.724 19.3701 152.75 19.4337 152.75 19.5V23.75C152.75 24.4403 153.31 25 154 25C154.69 25 155.25 24.4403 155.25 23.75V19.5C155.25 19.4337 155.276 19.3701 155.323 19.3232C155.37 19.2763 155.434 19.25 155.5 19.25H159.75C160.44 19.25 161 18.6903 161 18C161 17.3097 160.44 16.75 159.75 16.75Z"
                                fill="#F8842C"
                            />
                        </g>
                        <path
                            d="M188 18C188 17.1716 188.672 16.5 189.5 16.5H306.5C307.328 16.5 308 17.1716 308 18C308 18.8284 307.328 19.5 306.5 19.5H189.5C188.672 19.5 188 18.8284 188 18Z"
                            fill="#D6D6D6"
                        />
                        <defs>
                            <clipPath id="clip0_1_308">
                                <rect
                                    width="14"
                                    height="14"
                                    fill="white"
                                    transform="translate(147 11)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
                {/* <img
                    className="w-32 overflow-x-hidden rotate-90"
                    src="__after2.png"
                    alt="Rotated Image"
                /> */}
            </div>
        </div>
    );
}

export default MainSection;
