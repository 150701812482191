import { useQuery } from "react-query";
import { get } from "../utils/axios";
import { BASE_URL } from "../utils/constants";

const useGetProviders = () => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['Providers'],
    queryFn: () => get(`${BASE_URL}provider`),
  });
  return { data, isLoading, isFetching };
};
export default useGetProviders;
