import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  BlockQuote,
  Bold,
  ClassicEditor,
  Essentials,
  FontColor,
  FontSize,
  Italic,
  List,
  ListCommand,
  Mention,
  Paragraph,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import Cookies from "js-cookie";
import React, { useState } from "react";
import Dropdown from "./components/Dropdown";
import TextInput from "./components/TextInput";
import useCreateProvider from "./hooks/createProvider";
import useFilterItems from "./hooks/filterItems";
import useLogin from "./hooks/login";
import { prepareAddProviderData } from "./utils/helpers";

const AddProvider = () => {
  // GET ALL FILTERS
  const { data: age } = useFilterItems({
    filterItem: "Age",
  });
  const { data: availibility } = useFilterItems({
    filterItem: "Availibility",
  });
  const { data: billingOptions } = useFilterItems({
    filterItem: "BillingOptions",
  });
  const { data: ethnicity } = useFilterItems({
    filterItem: "Ethnicity",
  });
  const { data: focus } = useFilterItems({
    filterItem: "Focus",
  });
  const { data: gender } = useFilterItems({
    filterItem: "Gender",
  });
  const { data: insurance } = useFilterItems({
    filterItem: "Insurance",
  });
  const { data: languages } = useFilterItems({
    filterItem: "Languages",
  });
  const { data: locations } = useFilterItems({
    filterItem: "Location",
  });
  const { data: religion } = useFilterItems({
    filterItem: "Religion",
  });
  const { data: service } = useFilterItems({
    filterItem: "Service",
  });
  const { data: therapyStyle } = useFilterItems({
    filterItem: "TherapyStyle",
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [file, setFile] = useState({
    imageAsFile: "",
    preview: "",
  });
  const [providerFormData, setProviderFormData] = useState({
    name: "",
    providerType: "",
    address: "",
    bioData: "",
    license: "",
    schedule: "",
    sessionType: "",
    rates: "",
    age: "",
    availibility: "",
    billingOptions: [],
    ethnicity: "",
    focus: [],
    gender: "",
    insurance: [],
    languages: [],
    locations: [],
    religion: "",
    serviceType: [],
    therapyStyle: [],
  });
  const { createProvider, loading: creatProviderLoading } = useCreateProvider();
  const { login, loading } = useLogin();
  const handleSubmit = (e) => {
    e.preventDefault();
    const res = login(formData);
    console.log({ res });
  };
  // console.log({ TOK: Cookies.get("accessToken") });
  const fileInputRef = React.createRef();
  const editorRef = React.createRef(null);
  const handleFileChange = (e) => {
    console.log(e.target.files);
    setFile({
      imageAsFile: e.target.files[0],
      preview: URL.createObjectURL(e.target.files[0]),
    });
  };
  const handleDropdownClick = (name, item, multiple) => {
    if (multiple) {
      setProviderFormData((prev) => ({
        ...prev,
        [name]: prev[name].includes(item)
          ? prev[name].filter((i) => i !== item)
          : [...prev[name], item],
      }));
    } else {
      setProviderFormData({ ...providerFormData, [name]: item });
    }
  };
  const handleProviderSubmit = (e) => {
    e.preventDefault();
    const providerFormDataToSubmit = prepareAddProviderData({
      ...providerFormData,
      file: file.imageAsFile,
    });
    console.log({
      providerFormDataToSubmit: providerFormDataToSubmit.get("file"),
    });
    createProvider(providerFormDataToSubmit);
  };
  console.log({ providerFormData });

  return (
    <div class="max-w-2xl mx-auto">
      {/* <pre>{process.env.REACT_APP_BASE_URL}</pre> */}
      {Cookies.get("accessToken") ? (
        <form onSubmit={handleProviderSubmit}>
          <div className="flex flex-col items-center justify-center mt-8 w-auto">
            <label>{!file.preview ? "Add" : "Change"} Profile Picture</label>
            <input
              ref={fileInputRef}
              className="hidden"
              type="file"
              onChange={handleFileChange}
              accept=".png,.jpg,.jpeg"
            />
            <div className="pb-4" onClick={() => fileInputRef.current.click()}>
              {!file.preview ? (
                <svg
                  width="128px"
                  height="128px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#000000"
                    fill-rule="evenodd"
                    d="M11.3248,7.22461 C11.7513,6.58854 12,5.82332 12,5 C12,2.79086 10.2091,1 8,1 C5.79086,1 4,2.79086 4,5 C4,5.82332 4.24874,6.58854 4.67518,7.22461 C3.11714,7.77132 2,9.2552 2,11 L2,15 L14,15 L14,11 C14,9.2552 12.8829,7.77132 11.3248,7.22461 Z M10,5 C10,6.10457 9.10457,7 8,7 C6.89543,7 6,6.10457 6,5 C6,3.89543 6.89543,3 8,3 C9.10457,3 10,3.89543 10,5 Z M6,9 C4.89543,9 4,9.89543 4,11 L4,13 L12,13 L12,11 C12,9.89543 11.1046,9 10,9 L6,9 Z"
                  />
                </svg>
              ) : (
                <img
                  src={file.preview}
                  alt="Provider"
                  width={128}
                  height={128}
                  className="rounded-full w-32 h-32 object-cover"
                />
              )}
            </div>
            <div className="w-full space-y-3">
              <div className="flex gap-4">
                <TextInput
                  labelFor={"name"}
                  labelText={"Name"}
                  placeholder={"Name"}
                  value={providerFormData.name}
                  onChange={(e) =>
                    setProviderFormData({
                      ...providerFormData,
                      name: e.target.value,
                    })
                  }
                  required
                />
                <TextInput
                  labelFor={"providerType"}
                  labelText={"Provider Type"}
                  placeholder={"Provider Type"}
                  value={providerFormData.providerType}
                  onChange={(e) =>
                    setProviderFormData({
                      ...providerFormData,
                      providerType: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="flex gap-4">
                <TextInput
                  labelFor={"address"}
                  labelText={"Address"}
                  placeholder={"Address"}
                  value={providerFormData.address}
                  onChange={(e) =>
                    setProviderFormData({
                      ...providerFormData,
                      address: e.target.value,
                    })
                  }
                />
                <TextInput
                  labelFor={"license"}
                  labelText={"License"}
                  placeholder={"License"}
                  value={providerFormData.license}
                  onChange={(e) =>
                    setProviderFormData({
                      ...providerFormData,
                      license: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="flex gap-4">
                <TextInput
                  labelFor={"schedule"}
                  labelText={"Schedule"}
                  placeholder={"Schedule"}
                  value={providerFormData.schedule}
                  onChange={(e) =>
                    setProviderFormData({
                      ...providerFormData,
                      schedule: e.target.value,
                    })
                  }
                  required
                />
                <TextInput
                  labelFor={"rates"}
                  labelText={"Rates"}
                  placeholder={"Rates"}
                  value={providerFormData.rates}
                  onChange={(e) =>
                    setProviderFormData({
                      ...providerFormData,
                      rates: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <TextInput
                labelFor={"sessionType"}
                labelText={"Session Type"}
                placeholder={"Session Type"}
                value={providerFormData.sessionType}
                onChange={(e) =>
                  setProviderFormData({
                    ...providerFormData,
                    sessionType: e.target.value,
                  })
                }
                required
              />
              <div>
                <label
                  for={"bioData"}
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Bio Data
                </label>
                <CKEditor
                  ref={editorRef}
                  editor={ClassicEditor}
                  config={{
                    toolbar: {
                      items: [
                        "undo",
                        "redo",
                        "|",
                        "bold",
                        "italic",
                        "fontColor",
                        "fontSize",
                        "mention",
                        "bullets",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                    },
                    plugins: [
                      // Markdown,
                      Bold,
                      Essentials,
                      Italic,
                      Mention,
                      Paragraph,
                      // SlashCommand,
                      Undo,
                      FontColor,
                      FontSize,
                      List,
                      ListCommand,
                      BlockQuote,
                    ],
                    // licenseKey: "<YOUR_LICENSE_KEY>",
                    mention: {
                      // Mention configuration
                    },
                  }}
                  onChange={(_, editor) => {
                    setProviderFormData({
                      ...providerFormData,
                      bioData: editor.getData(),
                    });
                  }}
                />
              </div>
              <div className="flex items-center gap-4">
                <Dropdown
                  title={providerFormData.age.name || "Age"}
                  items={age}
                  onClick={(item) => handleDropdownClick("age", item, false)}
                  label={"Age"}
                  selectedIds={[providerFormData.age.id]}
                />
                <Dropdown
                  title={providerFormData.availibility.name || "Availability"}
                  items={availibility}
                  onClick={(item) =>
                    handleDropdownClick("availibility", item, false)
                  }
                  label={"Availability"}
                  required
                  selectedIds={[providerFormData.availibility.id]}
                />
                <Dropdown
                  title={providerFormData.ethnicity.name || "Ethnicity"}
                  items={ethnicity}
                  onClick={(item) =>
                    handleDropdownClick("ethnicity", item, false)
                  }
                  label={"Ethnicity"}
                  selectedIds={[providerFormData.ethnicity.id]}
                />
              </div>
              <div className="flex items-center gap-4">
                <Dropdown
                  title={
                    (providerFormData.focus.length > 0 &&
                      providerFormData.focus
                        .map((item) => item.name)
                        .join(", ")) ||
                    "Focus"
                  }
                  items={focus}
                  onClick={(item) => handleDropdownClick("focus", item, true)}
                  label={"Focus"}
                  multiple
                  selectedIds={providerFormData.focus.map((item) => item.id)}
                  required
                />
                <Dropdown
                  title={providerFormData.gender.name || "Gender"}
                  items={gender}
                  onClick={(item) => handleDropdownClick("gender", item, false)}
                  label={"Gender"}
                  required
                  selectedIds={[providerFormData.gender.id]}
                />
                <Dropdown
                  title={
                    (providerFormData.insurance.length > 0 &&
                      providerFormData.insurance
                        .map((item) => item.name)
                        .join(", ")) ||
                    "Insurance"
                  }
                  items={insurance}
                  onClick={(item) =>
                    handleDropdownClick("insurance", item, true)
                  }
                  label={"Insurance"}
                  multiple
                  selectedIds={providerFormData.insurance.map(
                    (item) => item.id
                  )}
                />
              </div>
              <div className="flex items-center gap-4">
                <Dropdown
                  title={
                    (providerFormData.billingOptions.length > 0 &&
                      providerFormData.billingOptions
                        .map((item) => item.name)
                        .join(", ")) ||
                    "Billing Options"
                  }
                  items={billingOptions}
                  onClick={(item) =>
                    handleDropdownClick("billingOptions", item, true)
                  }
                  label={"Billing Options"}
                  multiple
                  selectedIds={providerFormData.billingOptions.map(
                    (item) => item.id
                  )}
                />
                <Dropdown
                  title={
                    (providerFormData.therapyStyle.length > 0 &&
                      providerFormData.therapyStyle
                        .map((item) => item.name)
                        .join(", ")) ||
                    "Therapy Styles"
                  }
                  items={therapyStyle}
                  onClick={(item) =>
                    handleDropdownClick("therapyStyle", item, true)
                  }
                  label={"Therapy Styles"}
                  multiple
                  selectedIds={providerFormData.therapyStyle.map(
                    (item) => item.id
                  )}
                />
                <Dropdown
                  title={
                    (providerFormData.serviceType.length > 0 &&
                      providerFormData.serviceType
                        .map((item) => item.name)
                        .join(", ")) ||
                    "Service Types"
                  }
                  items={service}
                  onClick={(item) =>
                    handleDropdownClick("serviceType", item, true)
                  }
                  label={"Service Types"}
                  multiple
                  selectedIds={providerFormData.serviceType.map(
                    (item) => item.id
                  )}
                />
              </div>
              <div className="flex items-center gap-4">
                <Dropdown
                  title={
                    (providerFormData.languages.length > 0 &&
                      providerFormData.languages
                        .map((item) => item.name)
                        .join(", ")) ||
                    "Languages"
                  }
                  items={languages}
                  onClick={(item) =>
                    handleDropdownClick("languages", item, true)
                  }
                  label={"Languages"}
                  multiple
                  selectedIds={providerFormData.languages.map(
                    (item) => item.id
                  )}
                />
                <Dropdown
                  title={
                    (providerFormData.locations.length > 0 &&
                      providerFormData.locations
                        .map((item) => item.name)
                        .join(", ")) ||
                    "Locations"
                  }
                  items={locations}
                  onClick={(item) =>
                    handleDropdownClick("locations", item, true)
                  }
                  label={"Locations"}
                  multiple
                  selectedIds={providerFormData.locations.map(
                    (item) => item.id
                  )}
                />
                <Dropdown
                  title={providerFormData.religion.name || "Religion"}
                  items={religion}
                  onClick={(item) => handleDropdownClick("religion", item)}
                  label={"Religion"}
                  selectedIds={[providerFormData.religion.id]}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="w-full text-white bg-[#005B8C] hover:bg-[#005B8C99] focus:ring-4 focus:ring-[#005B8C80] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4"
          >
            {creatProviderLoading ? "Loading..." : "Add Provider to Directory"}
          </button>
        </form>
      ) : (
        <div class="bg-white shadow-md w-[70%] 2xl:w-[80%] mx-auto border border-gray-200 rounded-lg mt-10 lg:mt-16 p-4 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
          <form class="space-y-10" onSubmit={handleSubmit}>
            <h3 class="text-base lg:text-xl 3xl:text-2xl font-medium text-gray-900 dark:text-white">
              Sign in to Admin Account
            </h3>
            <div>
              <label
                for="email"
                class="text-sm lg:text-base 3xl:text-lg font-medium text-gray-900 block mb-2 dark:text-gray-300"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                class="bg-gray-50 border border-gray-300 lg:h-12 text-gray-900  sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="name@company.com"
                required
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div>
              <label
                for="password"
                class="text-sm lg:text-base 3xl:text-lg font-medium text-gray-900 block  mb-2 dark:text-gray-300"
              >
                Your password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 lg:h-12 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </div>
            <button
              type="submit"
              class="w-full text-white lg:h-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-[10px] md:text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {loading ? "Loading..." : "Login to your account"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddProvider;
