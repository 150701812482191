import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="sticky top-0 z-50 bg-white shadow-md px-8 lg:px-16 LapTopL:px-24 2xl:px-28 3xl:px-28 4xl:px-36">
            <div className="flex justify-between items-center py-4 lg:py-6  LapTopL:h-[100px] 2xl:h-[115px] 3xl:h-[130px] 4xl:py-12 4xl:h-[150px]">
                <Link className='' to="/">
                    <svg className='w-[90px] md:w-[150px] lg:w-[179px] LapTopL:w-[210px] 2xl:w-[230px] 3xl:w-[270px] 4xl:w-[340px]' viewBox="0 0 179 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1578 17.7737C7.8287 17.9242 4.55366 18.6808 1.48975 20.0073C1.02739 20.2069 -0.0154871 20.7093 -7.51678e-05 20.7229C0.00545734 20.7281 0.244541 20.6721 0.533416 20.6005C1.86556 20.2625 3.79364 19.9169 5.25976 19.7521C7.56641 19.4913 10.5117 19.4701 13.0626 19.6989C14.1477 19.7945 14.8096 19.8929 17.2515 20.3237C25.6138 21.7945 30.4611 22.3477 34.1106 22.2437C37.2325 22.1561 40.6943 21.3557 43.8786 19.9833C44.3923 19.7629 45.4759 19.2441 45.4593 19.2281C45.4566 19.2257 45.2361 19.2733 44.9681 19.3397C41.5265 20.1721 39.9889 20.3585 36.1272 20.4061C34.6168 20.4221 34.1386 20.4061 33.014 20.2945C31.564 20.1485 30.0035 19.9301 26.0651 19.3241C21.7379 18.6565 19.6703 18.3693 17.5265 18.1273C14.8942 17.8349 12.6263 17.7069 11.1554 17.7737" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79521 20.1139C6.72309 20.1591 5.88768 20.2231 5.04437 20.3239C4.36664 20.4039 2.9847 20.6191 2.96099 20.6459C2.90566 20.7071 3.02935 22.1323 3.17359 23.1455L3.20521 23.3663H6.02679C8.70412 23.3663 8.84876 23.3635 8.83532 23.3183C8.82742 23.2943 8.78553 23.1003 8.74364 22.8875C8.5907 22.0959 8.49822 21.2935 8.46702 20.4875L8.44844 20.0967L8.20699 20.1019C8.07539 20.1047 7.89085 20.1099 7.79719 20.1151" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.3985 24.3423C3.63096 25.3779 3.93872 26.3947 4.31927 27.3843L4.49552 27.834H10.7393L10.6263 27.6532C10.1126 26.8391 9.5384 25.6187 9.21 24.6319L9.09738 24.2971H6.23984C3.53642 24.2971 3.38665 24.2995 3.39732 24.3423" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.2006 24.6319C35.875 25.6055 35.3388 26.7623 34.8266 27.5868L34.6741 27.834H40.9139L41.0898 27.3843C41.4707 26.3947 41.779 25.3779 42.0121 24.3423C42.0224 24.2995 41.8727 24.2971 39.1692 24.2971H36.3137L36.2006 24.6319Z" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.82632 28.5652C4.82632 28.5944 5.2049 29.3444 5.39379 29.6852C5.78974 30.4089 6.22839 31.1078 6.70737 31.778L6.9674 32.1424H15.0018L14.7402 31.9664C13.4586 31.0847 12.3246 30.0011 11.3811 28.7568L11.2262 28.552H8.02529C6.26477 28.552 4.82434 28.5572 4.82434 28.5652" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.041 28.7435C33.0935 29.9929 31.9547 31.0809 30.6677 31.9663L30.4077 32.1419H38.4417L38.7017 31.7775C39.1807 31.1073 39.6194 30.4084 40.0153 29.6847C40.2046 29.3443 40.5828 28.5943 40.5828 28.5647C40.5828 28.5567 39.1431 28.5515 37.3818 28.5515H34.1856L34.041 28.7435Z" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.4064 32.6924C7.85809 33.2376 8.23113 33.6496 8.72511 34.1496C9.48978 34.9236 10.0414 35.4128 10.835 36.0192L11.0503 36.184H34.3592L34.5746 36.0192C35.7692 35.1021 36.8616 34.0559 37.8324 32.8992L38.03 32.6592L33.8107 32.6512L29.5913 32.646L29.063 32.9092C27.4032 33.7416 25.8502 34.1856 23.9375 34.38C23.4854 34.4252 21.9197 34.4252 21.468 34.38C19.5317 34.1832 17.9055 33.7124 16.2517 32.8668L15.8209 32.646H11.5925C7.5704 32.646 7.36806 32.6488 7.40363 32.6912" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8016 36.7079C13.998 38.1944 16.6461 39.2608 19.276 39.7208C20.5161 39.9364 21.2989 40.0008 22.7046 40.0008C24.1102 40.0008 24.8931 39.9372 26.1331 39.7208C28.7631 39.2608 31.4112 38.1944 33.6076 36.7079L33.8703 36.5295H11.5392L11.8016 36.7079Z" fill="#016D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M93.2689 19.7278V34.662H96.0968L96.1363 28.8827C96.1759 23.1583 96.1976 22.5279 96.4667 21.1671C96.8583 19.1366 98.3636 17.0834 100.239 16.0254C101.83 15.123 103.867 14.921 105.588 15.4966C107.355 16.0878 108.516 17.651 108.923 20.0003C109.023 20.5915 109.046 21.7735 109.077 27.6771L109.107 34.662H111.959L111.919 27.8715C111.879 20.5135 111.858 20.0715 111.435 18.3278C110.774 15.6054 108.953 13.661 106.294 12.8286C105.633 12.6286 105.533 12.6186 104.027 12.6186C102.552 12.6186 102.407 12.6342 101.683 12.8366C99.7933 13.3566 98.0257 14.5566 96.5118 16.3442L96.1122 16.811V4.7937H93.2689V19.7278Z" fill="#4A4A4A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M80.9741 9.11062V13.1167H78.6303H76.2865V14.3223V15.5279H78.6303H80.9741V25.0948V34.6621H82.3572H83.7404V25.0948V15.5279H86.3916H89.0425V14.3223V13.1167H86.3916H83.7404V9.11062V5.10498H82.3572H80.9741V9.11062Z" fill="#4A4A4A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M60.0339 12.6574C58.2896 12.9142 56.5452 13.6686 55.2159 14.7422C52.9491 16.5622 51.4127 19.3159 50.9973 22.2803C50.8819 23.1439 50.9511 25.5627 51.1202 26.3407C51.896 29.8876 54.2477 32.9908 57.1447 34.282C58.6124 34.9356 60.0418 35.2076 62.0011 35.2076C64.3603 35.2076 66.0584 34.78 67.7569 33.7612C69.4561 32.75 70.8077 31.3108 71.8754 29.3896C72.1679 28.8608 72.2137 28.7208 72.1291 28.6664C72.0679 28.6264 71.6759 28.4175 71.253 28.1919C70.8306 27.9739 70.3694 27.7251 70.2311 27.6475C70.0928 27.5699 69.939 27.5071 69.8853 27.5151C69.8316 27.5151 69.532 27.9195 69.2167 28.4175C68.5251 29.4908 67.5648 30.5564 66.8499 31.0308C64.6599 32.4932 61.9398 32.9756 59.596 32.322C57.2522 31.6608 55.2542 29.7476 54.3401 27.2971C54.1327 26.7371 53.8869 25.5395 53.8102 24.7227L53.7561 24.1627H72.9057V23.7027C72.9057 23.1191 72.7081 21.5479 72.5524 20.9335C72.045 18.8879 71.1843 17.309 69.7782 15.831C68.103 14.081 66.1971 13.071 63.8383 12.6886C63.0388 12.5642 60.8258 12.541 60.0343 12.6574M63.4308 15.411C64.9602 15.7066 66.5354 16.4998 67.5881 17.5034C68.4575 18.3278 69.2084 19.6267 69.6245 21.0271L69.8165 21.6723H61.9473C57.6209 21.6723 54.0785 21.6571 54.0785 21.6415C54.0785 21.5015 54.6164 20.1091 54.8626 19.6111C55.9766 17.371 57.7592 15.9634 60.1493 15.451C60.9871 15.2646 62.5852 15.249 63.4293 15.411" fill="#4A4A4A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M126.543 12.6108C126.481 12.6264 126.166 12.6808 125.851 12.7272C124.567 12.9372 123.031 13.5752 121.862 14.384C120.979 14.9984 119.757 16.2741 119.104 17.2697C118.174 18.6777 117.721 19.7357 117.352 21.3221C117.152 22.1857 117.137 22.3801 117.129 23.8889C117.129 25.6782 117.198 26.1602 117.643 27.5838C118.388 29.9638 120.125 32.3362 122.046 33.5886C122.653 33.993 123.614 34.4518 124.282 34.6618C125.489 35.043 126.142 35.1442 127.732 35.1818C130.038 35.2442 131.482 34.9874 133.073 34.225C135.094 33.253 136.669 31.7674 137.907 29.6438C138.49 28.6406 138.552 28.7726 137.261 28.0958C136.639 27.7694 136.108 27.5046 136.07 27.5046C136.032 27.5046 135.786 27.847 135.509 28.2594C134.395 29.9706 133.442 30.8962 132.097 31.5794C130.675 32.295 129.515 32.575 127.963 32.575C125.704 32.5826 124.044 31.8982 122.5 30.3506C120.955 28.803 120.148 26.959 119.987 24.6021L119.956 24.1589H139.067V23.5545C139.059 21.8901 138.514 19.6809 137.738 18.2501C136.5 15.9553 134.458 14.1272 132.113 13.2252C130.906 12.7664 130.099 12.634 128.309 12.6108C127.402 12.5952 126.603 12.5952 126.541 12.6108M129.592 15.4108C130.699 15.6209 132.013 16.1653 132.881 16.7709C133.395 17.1285 134.203 17.9141 134.556 18.3965C134.994 18.9965 135.501 20.0301 135.763 20.8765C135.893 21.2733 135.993 21.6077 135.993 21.6309C135.993 21.6541 132.451 21.6709 128.117 21.6709H120.249L120.479 20.9865C120.956 19.6333 121.325 18.9333 122.07 17.9921C123.039 16.7785 124.675 15.7921 126.312 15.4408C127.15 15.262 128.756 15.2464 129.592 15.4096" fill="#4A4A4A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M150.595 12.6965C149.259 13.0617 147.851 14.1121 146.769 15.5601L146.37 16.097V13.1165H143.525V34.6619H146.353L146.392 28.7663C146.432 22.5434 146.477 21.6414 146.823 20.1558C147.307 18.0946 148.429 16.4454 149.82 15.7377C150.78 15.2477 151.472 15.1933 152.409 15.5197C152.655 15.6133 152.863 15.6833 152.87 15.6833C152.893 15.6833 154.284 13.3729 154.284 13.3265C154.284 13.2329 153.37 12.8065 152.924 12.6865C152.371 12.5389 151.157 12.5465 150.596 12.6945" fill="#4A4A4A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M165.118 12.6884C162.797 13.0852 160.769 14.2052 159.048 16.0485C157.234 17.9849 156.273 20.1709 156.051 22.8385C155.874 24.9778 156.288 27.3034 157.165 29.0614C158.002 30.749 159.578 32.5146 161.199 33.5882C162.313 34.3194 163.773 34.8795 165.157 35.0895C166.271 35.2683 168.384 35.2139 169.346 34.9883C171.367 34.5058 173.065 33.5414 174.633 31.9626L175.416 31.169V34.6615H178.183V14.0496H175.416V16.8897L175.209 16.6173C174.778 16.0497 173.664 14.9529 173.035 14.4704C171.875 13.5836 170.346 12.9304 168.863 12.6892C168.002 12.5492 165.935 12.5492 165.12 12.6892M168.544 15.3797C171.472 15.7921 174.053 17.9545 175.106 20.8789C175.744 22.6525 175.775 25.039 175.176 26.8914C174.537 28.8826 173.147 30.5474 171.263 31.5662C167.383 33.6742 162.757 32.4862 160.313 28.7506C159.382 27.3325 158.883 25.6694 158.876 23.9669C158.868 22.4577 159.16 21.2445 159.844 19.9065C161.535 16.6089 164.886 14.8665 168.544 15.3797Z" fill="#4A4A4A" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.7889 0.0195346C17.9828 0.191917 14.3072 1.47514 11.2053 3.71447C8.1035 5.95379 5.70789 9.05359 4.3074 12.6401C3.55656 14.5625 3.14359 16.4161 2.96735 18.6737L2.95154 18.8653L3.53759 18.6713C4.83764 18.2434 6.16409 17.9026 7.50835 17.6509C7.74743 17.6057 8.64607 17.4621 8.68519 17.4621C8.69309 17.4621 8.70653 17.4061 8.7168 17.3369C8.82706 16.5737 9.26334 15.1509 9.668 14.2169C10.5978 12.0851 12.0233 10.2126 13.8228 8.75918C15.6223 7.30575 17.7424 6.31449 20.0023 5.86999C20.9191 5.68919 21.681 5.61999 22.6955 5.61999C23.9747 5.61999 25.0045 5.74759 26.2157 6.05999C31.061 7.30081 34.9733 11.1328 36.3517 15.9861C36.6648 17.0608 36.8491 18.1697 36.9006 19.2889L36.9322 19.8793L36.9978 19.8685C37.0345 19.8633 37.3534 19.8501 37.7091 19.8421C39.4431 19.8021 40.7148 19.6773 42.3777 19.3845C42.4592 19.3713 42.4643 19.3633 42.4512 19.2729C42.4461 19.2197 42.4331 19.0149 42.4252 18.8181C42.3442 16.8801 41.8494 14.6001 41.0831 12.6401C38.9816 7.26081 34.5757 2.98196 29.2108 1.10715C26.8268 0.273615 24.3085 -0.095423 21.7889 0.0195346Z" fill="#F89A40" />
                    </svg>
                </Link>
                <nav className="relative">
                <button
                    className="lg:hidden text-xl focus:outline-none"
                    onClick={toggleMenu}
                    aria-label="Toggle menu"
                    aria-expanded={isOpen}
                >
                    ☰
                </button>
                
                        <ul
                            className={`absolute w-[260px] mobileM:w-[312px] mobileL:w-[365px] lg:w-[100%] lg:space-x-6 LapTopL:space-x-9 2xl:space-x-10 3xl:space-x-14 4xl:space-x-20  text-center right-0 top-10 bg-white shadow-md rounded-lg lg:static lg:flex lg:justify-center lg:items-center lg:shadow-none lg:bg-transparent ${isOpen ? "block" : "hidden"
                                }`}
                        >
                            <li className="border-b  py-4 lg:py-0 lg:border-none">
                                <Link
                                    to="/"
                                    className="block hover:text-secondary transition-colors lg:text-sm lg:font-semibold  LapTopL:text-lg 3xl:text-2xl 4xl:text-3xl"
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="border-b py-4 lg:py-0 lg:border-none">
                                <Link
                                    to="/Provider"
                                    className="block  hover:text-secondary transition-colors lg:text-sm lg:font-semibold  LapTopL:text-lg 3xl:text-2xl 4xl:text-3xl "
                                >
                                    Provider Directory
                                </Link>
                            </li>
                            <li className="border-b py-4 lg:py-0 lg:border-none">
                                <Link
                                    to="/FAQ"
                                    className="block  hover:text-secondary transition-colors lg:text-sm lg:font-semibold  LapTopL:text-lg 3xl:text-2xl 4xl:text-3xl "
                                >
                                    FAQ
                                </Link>
                            </li>
                            <li className="border-b py-4 lg:py-0 lg:border-none">
                                <Link
                                    to="/Contact"
                                    className="block  hover:text-secondary transition-colors lg:text-sm lg:font-semibold  LapTopL:text-lg 3xl:text-2xl 4xl:text-3xl "
                                >
                                    Contact
                                </Link>
                            </li>
                            <li className="border-b py-4 md:py-5 lg:py-0 lg:border-none">
                                <Link
                                    to="/Blog"
                                    className=" border-b hover:text-secondary transition-colors lg:text-sm lg:font-semibold  LapTopL:text-lg 3xl:text-2xl 4xl:text-3xl "
                                >
                                    Blog
                                </Link>
                            </li>
                            <li className="flex justify-center items-center gap-1 4xl:gap-3 py-4 md:py-6 lg:py-0 LapTopL:pl-4 hover:text-secondary hover:underline transition-colors lg:text-sm font-semibold ">
                                <button className="font-bold LapTopL:text-lg 3xl:text-2xl 4xl:text-4xl md:ml-5 lg:ml-6 ">Contact Us</button>
                                <FaArrowRight className='mt-0.5 4xl:text-2xl' />
                            </li>
                        </ul>
                    </nav>
                
            </div>
        </header>
    );
}

export default Navbar;
