import React from 'react'
import { FaPlus } from 'react-icons/fa';
function Faq() {
  return (
    <div className=' w-[90%] md:w-[60%] lg:w-[70%] LapTopL:w-[57%] 3xl:w-[50%] flex flex-col gap-y-[14px] md:gap-y-[24px] items-center justify-between text-center mx-auto'>
        <div className='text-2xl md:text-4xl LapTopL:text-5xl 3xl:text-6xl 4xl:text-8xl font-semibold py-6'>FAQs</div>
        <div className='flex justify-between items-center text-center w-[100%] h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px]  border-2 p-4 4xl:p-10 rounded-lg'>
        <h1 className=' text-[10px] md:text-[13px] lg:text-lg LapTopL:text-xl 4xl:text-3xl font-semibold'>What can I expect the first time I go to Ethera?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>How do I set up initial consultation?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className='text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>What is your cancellation policy?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>Do you offer evening or weekend appointments?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>Can I use insurance for therapy?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>Do I need therapy?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>Why is it so important to find the right therapist?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>What makes a good match?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between w-[100%]  h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold'>How will I know if my therapist and I are a good fit?</h1>
            <button><FaPlus /></button>
        </div>
        <div className='flex justify-between  w-[100%] h-[50px] md:h-[62px] lg:h-[82px] 3xl:h-[110px] 4xl:h-[130px] items-center text-center border-2 p-4 4xl:p-10 rounded-lg'>
            <h1 className=' text-[10px] md:text-[13px] lg:text-lg 3xl:text-2xl 4xl:text-3xl font-semibold '>What if I don’t feel like the match I was provided is a good fit?</h1>
            <button><FaPlus /></button>
        </div>
    </div>
    )
}

export default Faq