import { useQuery } from "react-query";
import { get } from "../utils/axios";
import { BASE_URL } from "../utils/constants";

const useFilterItems = ({ filterItem }) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [filterItem],
    queryFn: () => get(`${BASE_URL}filter-items?filterItem=${filterItem}`),
  });
  return { data, isLoading, isFetching };
};
export default useFilterItems;
