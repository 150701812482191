export const prepareAddProviderData = (data) => {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("name", data.name);
  formData.append("providerType", data.providerType);
  formData.append("license", data.license);
  formData.append("schedule", data.schedule);
  formData.append("bioData", data.bioData);
  formData.append("sessionType", data.sessionType);
  formData.append("rates", data.rates);
  formData.append("availability", data.availibility.id);
  formData.append(
    "billingOptions",
    data.billingOptions.map((item) => item.id).join(",")
  );
  formData.append("focus", data.focus.map((item) => item.id).join(","));
  formData.append("languages", data.languages.map((item) => item.id).join(","));
  formData.append("location", data.locations.map((item) => item.id).join(","));
  formData.append(
    "serviceType",
    data.serviceType.map((item) => item.id).join(",")
  );
  formData.append(
    "therapyStyles",
    data.therapyStyle.map((item) => item.id).join(",")
  );
  if (data.insurance.length)
    formData.append(
      "insurance",
      data.insurance.map((item) => item.id).join(",")
    );
  if (data.address) formData.append("address", data.address || "");
  if (data.age.id) formData.append("age", data.age.id || "");
  if (data.ethnicity.id) formData.append("ethnicity", data.ethnicity.id);
  if (data.gender.id) formData.append("gender", data.gender.id);
  if (data.religion.id) formData.append("religion", data.religion.id);

  return formData;
};
