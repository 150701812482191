import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { postFormData } from "../utils/axios";
import { BASE_URL } from "../utils/constants";

const useCreateProvider = () => {
  const {
    mutate: createProvider,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: (values) =>
      postFormData(`${BASE_URL}provider`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log(response);

      // Cookies.set("accessToken", response.access);
      //   successToast(response.message);
      //   callback(response.data.accessToken, response.data.refreshToken);
    },
    onError: (error) => {
      //   errorToast(error.response?.data.message);
    },
  });
  return { createProvider, loading, error };
};
export default useCreateProvider;
